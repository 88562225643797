import React from 'react';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';

import {
  BedsheetCategoryHeroSection,
  BedsheetCategoryItemsSection,
  WoosaPromiseIconGridSection,
} from '../../../components/sections';

const BedsheetCategoryPage: React.FC = () => (
  <Layout>
    <SEO
      title="Shop Bedsheets, Fitted Sheets, Duvet Covers & Pillowcases | Woosa Sleep Singapore"
      description="Luxuriously soft bedsheets that only get better with time."
      meta={[]}
    />
    <BedsheetCategoryHeroSection />
    <BedsheetCategoryItemsSection />
    <WoosaPromiseIconGridSection version="v2" />
  </Layout>
);

export default BedsheetCategoryPage;
